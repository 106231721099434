<template>
  <div>
   
 
 
      <el-form ref="form" :model="form" :rules="rules" label-width="150px">
        <!--内部步骤条-->
        <el-steps :active="active" finish-status="success" v-if="formShowin" style="margin-left: 15px; margin-bottom: 15px">
          <el-step title="企业基本信息"></el-step>
          <el-step title="联系信息"></el-step>
          <el-step title="特殊资质与认证"></el-step>
          <el-step title="其他信息"></el-step>
        </el-steps>
 
         
        <!-- 内部委托单 -->
        <el-form ref="in" :model="form" :rules="rules" label-width="150px" v-if="formShowin">
          <!--step1-->
          <div v-show="active == 1">
            <el-row >
              <el-col :span="12">
                <el-form-item label="企业名称" prop="entrustUnit">
                  <el-input v-model="form.entrustUnit" placeholder="请输入企业名称" />
                </el-form-item>
              </el-col >
              <el-col :span="12">
                <el-form-item label="委托日期" prop="entrustDate">
                  <el-date-picker clearable
                                  v-model="form.entrustDate"
                                  type="date"
                                  value-format="yyyy-MM-dd"
                                  placeholder="请选择委托日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="委托人姓名" prop="entrustName">
                  <el-input v-model="form.entrustName" placeholder="请输入委托人姓名" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="委托人电话" prop="entrustPhone">
                  <el-input v-model="form.entrustPhone" placeholder="请输入委托人电话" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="检测物品名称" prop="productName">
                  <el-input v-model="form.productName" placeholder="请输入检测物品名称" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="研制阶段" prop="developPhase">
                  <el-input v-model="form.developPhase" placeholder="请输入研制阶段" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="检测物品编号" prop="productId">
                  <el-input v-model="form.productId" placeholder="请输入检测物品编号" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品批次" prop="nProductPhase">
                  <el-input v-model="form.nProductPhase" placeholder="请输入产品批次" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="任务代号" prop="taskCode">
                  <el-input v-model="form.taskCode" placeholder="请输入任务代号" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="检测物品数量" prop="productNum">
                  <el-input v-model="form.productNum" placeholder="请输入检测物品数量" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!--step2-->
          <div v-show="active == 2">
            <el-row>
              <el-col :span="12">
                <el-form-item label="盖认可标识" prop="productLogo">
                   
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="附件数量" prop="annexNum">
                  <el-input v-model="form.annexNum" placeholder="请输入附件数量" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="是否为相机内参数" label-width="200px">
                  <el-switch
                    v-model="form.nIsInCamera"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value="0"
                    :inactive-value="1"
                  >
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="相机内参数" prop="nIsCameraParameter" v-show="!form.nIsInCamera">
                  <el-input v-model="form.nIsCameraParameter" placeholder="请输入相机内参数" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="是否为相机外参数" label-width="200px">
                  <el-switch
                    v-model="form.nIsOutCamera"
                    active-color="#13ce66"
                    inactive-color="#ff4949"

                    :active-value="0"
                    :inactive-value="1"
                  >
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="相机外参数" prop="nOutCameraParameter" v-show="!form.nIsOutCamera">
                  <el-input v-model="form.nOutCameraParameter" placeholder="请输入相机外参数" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="是否有精度验证" label-width="200px">
                  <el-switch
                    v-model="form.nIsAccuracy"
                    active-color="#13ce66"
                    inactive-color="#ff4949"

                    :active-value="0"
                    :inactive-value="1"
                  >
                  </el-switch>
                  <!--<el-radio-group v-model="form.nIsAccuracy">
                   <el-radio
                  v-for="dict in dict.type.is_restricted"
                 :key="dict.value"
                  :label="parseInt(dict.value)"
                 >{{dict.label}}</el-radio>
                </el-radio-group>-->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="精度验证" prop="nVarifyAccuracy" v-show="!form.nIsAccuracy">
                  <el-input v-model="form.nVarifyAccuracy" placeholder="请输入精度验证" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="是否有其他参数" label-width="200px">
                  <el-switch
                    v-model="form.nIsOtherParameter"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value="0"
                    :inactive-value="1" >
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="其他参数" prop="nOtherParameter" v-show="!form.nIsOtherParameter">
                  <el-input v-model="form.nOtherParameter" placeholder="请输入其他参数" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="非固定场所" label-width="200px">
                  <el-switch
                    v-model="form.nonstationary"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value="0"
                    :inactive-value="1">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="接收方承接岗位" prop="nUndertakePost">
                  <el-input v-model="form.nUndertakePost" placeholder="请输入接收方承接岗位" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!--step3-->
          <div v-show="active == 3">
            <el-row>
              <el-col :span="12">
                <el-form-item label="接收人" prop="undertakePerson">
                  <el-input v-model="form.undertakePerson" placeholder="请输入接收人" />
                </el-form-item>
              </el-col>
              <el-col :span="12">

                <el-form-item label="接收日期" prop="undertakeDate">
                  <el-date-picker clearable
                                  v-model="form.undertakeDate"
                                  type="date"
                                  value-format="yyyy-MM-dd"
                                  placeholder="请选择接收日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="是否允许分包" label-width="200px">
                  <el-switch
                    v-model="form.allowSub"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value="0"
                    :inactive-value="1">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="委托确定人" prop="entrustEnsureName">
                  <el-input v-model="form.entrustEnsureName" placeholder="请输入委托确定人" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="委托确定日期" prop="entrustEnsureDate">
                  <el-date-picker clearable
                                  v-model="form.entrustEnsureDate"
                                  type="date"
                                  value-format="yyyy-MM-dd"
                                  placeholder="请选择委托确定日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="满意度调查" prop="satisfaction">
                   
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="检测物品取走人" prop="itemsRemovePer">
                  <el-input v-model="form.itemsRemovePer" placeholder="请输入检测物品取走人" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="检测物品取走日期" prop="itemsRemoveDate">
                  <el-date-picker clearable
                                  v-model="form.itemsRemoveDate"
                                  type="date"
                                  value-format="yyyy-MM-dd"
                                  placeholder="请选择检测物品取走日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="检测报告取走人" prop="reportRemovePer">
                  <el-input v-model="form.reportRemovePer" placeholder="请输入检测报告取走人" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="检测报告取走日期" prop="reportRemoveDate">
                  <el-date-picker clearable
                                  v-model="form.reportRemoveDate"
                                  type="date"
                                  value-format="yyyy-MM-dd"
                                  placeholder="请选择检测报告取走日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!--step4-->
          <div v-show="active == 4">
          <el-row>
            <el-col :span="12">
              <el-form-item label="文件上传" prop="uploadFile" label-width="80px">
                
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选择文件" label-width="80px">
                
              </el-form-item>
            </el-col>

          </el-row>
          </div>
        </el-form>
        <!--内部步骤按钮-->
       
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="formShowin">
        <el-button type="primary" plain v-if="active > 1" style="margin-top: 15px; margin-left: 15px;" @click="pre">上一步</el-button>
        <el-button type="primary" plain v-if="active < 4" style="margin-top: 15px; margin-left: 15px;" @click="next">下一步</el-button>
        <el-button type="primary" v-if="active==4" >确 定</el-button>
        <el-button >取 消</el-button>
        </div>
      </div>
  </div>
</template>

<script>
import { getCodeImg, register } from '@/api/login'

export default {
  name: 'Register',
  components: {},
  data() {
    const validateNewPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else if (!/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){5,20}$/.test(value)) {
        callback(new Error('请输入5-20位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种'))
      } else {
        if (this.form.confirmPassword !== '') {
          this.$refs.form.validateField('confirmPassword')
        }
        callback()
      }
    }
    const validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码确认'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      formShowout:true,
      formShowin:true,
      open:true,
      active:1,
      title:'',
      codeUrl: '',
      isRegisterError: false,
      registerErrorInfo: '',
      form: {
        username: undefined,
        password: undefined,
        confirmPassword: undefined,
        code: undefined,
        uuid: undefined
      },
      rules: {
        username: [
          { required: true, trigger: 'blur', message: '请输入您的账号' },
          { min: 2, max: 20, message: '用户账号长度必须介于 2 和 20 之间', trigger: 'blur' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '请输入您的密码' },
          { required: true, validator: validateNewPass, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, trigger: 'blur', message: '请再次输入您的密码' },
          { required: true, validator: validateConfirmPass, trigger: 'blur' }
        ],
        code: [{ required: true, trigger: 'change', message: '请输入验证码' }]
      },
      registerBtn: false,
      registering: false,
      captchaOnOff: true
    }
  },
  computed: {},
  created() {
    this.getCode()
  },
  methods: {
    closeDialog(){},
    /** 步骤条下一步的方法 */
    next() {
      if (this.active++ > 3) this.active = 1
    },
    /** 步骤条上一步的方法 */
    pre() {
      if (this.active-- < 2) this.active = 1
    },

    getCode() {
      getCodeImg().then((res) => {
        this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff
        if (this.captchaOnOff) {
          this.codeUrl = 'data:image/gif;base64,' + res.img
          this.form.uuid = res.uuid
        }
      })
    },
    handleRegister() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.registering = true
          register(this.form)
            .then(res => this.registerSuccess(res))
            .catch(err => this.requestFailed(err))
            .finally(() => {
              this.registering = false
            })
        } else {
          setTimeout(() => {
            this.registering = false
          }, 600)
        }
      })
    },
    registerSuccess(res) {
      this.$router.push({ path: '/login' })
      // 延迟 1 秒显示欢迎信息
      const username = this.form.username
      setTimeout(() => {
        this.$notification.success({
          message: '恭喜你，您的账号 ' + username + ' 注册成功！'
        })
      }, 1000)
      this.handleCloseRegisterError()
    },
    requestFailed(err) {
      this.isRegisterError = true
      this.registerErrorInfo = err
      this.form.code = undefined
      if (this.captchaOnOff) {
        this.getCode()
      }
    },
    handleCloseRegisterError() {
      this.isRegisterError = false
      this.registerErrorInfo = ''
    }
  }
}
</script>
<style scoped>
.dialog-footer {
	position: sticky;
	bottom: 10px;
	text-align: center;
	background-color: #fff;
}

</style>